import { useDispatch, useSelector } from "react-redux";
import { setPreference } from "../features/preference/preferenceSlice";
import "../style/css/BranchPreference.css";
import Toolbar from "./Toolbar";
import $ from "jquery";
import axios from "axios";
import showResponse from "./ResponseUtil";
import { showProgressbar, hideProgressbar } from "./ProgressbarHelper";
import { setSubjectCache } from "../features/subjectCache/subjectCacheSlice";

const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;

const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";

// const API_URL = "http://localhost:8080/api/";

const Preference = () => {
	const { instituteCache } = useSelector((state) => state.instituteCache);
	const { branchCache } = useSelector((state) => state.branchCache);

	const dispatch = useDispatch();

	let { preference } = useSelector((state) => state.preference);

	const branch = preference.branch ? preference.branch : "";
	const semester = preference.semester ? preference.semester : "";
	const institute = preference.institute ? preference.institute : "";

	const onPreferenceChange = () => {
		let data = {
			branch: $("#branch-preference-select").val(),
			branchTitle: $("#branch-preference-select option:selected").text(),
			semester: $("#semester-preference-select").val(),
			semesterTitle: $(
				"#semester-preference-select option:selected"
			).text(),
			institute: $("#institute-preference-select").val(),
			instituteTitle: $(
				"#institute-preference-select option:selected"
			).text(),
			refreshTimestamp: -1,
		};
		dispatch(setPreference(data));
		getSubjects();
	};

	const onSavePreference = () => {
		onPreferenceChange();
	};

	const getSubjects = async () => {
		let data = {
			branchCode: $("#branch-preference-select").val(),
			semester: $("#semester-preference-select").val(),
		};
		try {
			showProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			const response = await axios.post(API_URL + "subjects", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				hideProgressbar(
					"#toolbarProgressbar",
					"#toolbar-action-button"
				);
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push({
					title: value.title,
					code: value.code,
				});
			});
			dispatch(setSubjectCache(array));
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			showResponse(TYPE_SUCCESS, "Preference saved successfully", 2500);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				2500
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
		}
	};

	return (
		<div className="component-container">
			{Toolbar("Preference", "Save", onSavePreference)}
			<div className="component-wrapper">
				<div className="root">
					<div id="preference-container">
						<div className="selection-wrapper">
							<div className="selection-option-row">
								<p className="selection-label">
									Default Branch
								</p>

								<div
									className="progressbar"
									id="branchProgressbar"
								></div>
								<select
									name="branch-preference-select"
									id="branch-preference-select"
									className="selection-select"
									defaultValue={branch}
								>
									{branchCache.map((branch, index) => {
										return (
											<option
												key={index}
												value={branch.code}
											>
												{branch.title}
											</option>
										);
									})}
								</select>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">
									Default Semester
								</p>

								<div
									className="progressbar"
									id="semesterProgressbar"
								></div>
								<select
									name="branch-preference-select"
									id="semester-preference-select"
									className="selection-select"
									defaultValue={semester}
								>
									<option value="1">I & II Sem</option>
									<option value="3">III & IV Sem</option>
									<option value="5">V & VI Sem</option>
									<option value="7">VII & VIII Sem</option>
								</select>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">
									Default Institute
								</p>

								<div
									className="progressbar"
									id="instituteProgressbar"
								></div>
								<select
									name="institute-preference-select"
									id="institute-preference-select"
									className="selection-select"
									defaultValue={institute}
								>
									{instituteCache.map((institute, index) => {
										return (
											<option
												key={index}
												value={institute.code}
											>
												{institute.title}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Preference;
