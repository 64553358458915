import "../style/css/NavigationHeader.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import Logo from "../img/logo64.jpg";

const NavigationHeader = () => {
	const onMenuClick = () => {
		$("#sidebar").toggleClass("show-sidebar");
		if ($("#sidebar").is(":visible")) {
			$("#overlay-background-mobile-sidebar").show();
		} else {
			$("#overlay-background-mobile-sidebar").hide();
		}
	};
	return (
		<div id="nav-header">
			<p id="menu-bar-mobile" onClick={onMenuClick}>
				<FontAwesomeIcon icon={faBars} />
			</p>
			<img src={Logo} alt="Logo" />
			<NavLink to="/" style={{ width: "100%" }}>
				<h1 id="brand-name">Engineer Notes</h1>
			</NavLink>
		</div>
	);
};

export default NavigationHeader;
