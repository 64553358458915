import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const subjectCache = JSON.parse(localStorage.getItem("subjectCache"));

const initialState = {
	subjectCache: subjectCache ? subjectCache : [],
};

export const setSubjectCache = createAsyncThunk(
	"subjectCache/set",
	async (subjectCache, thunk) => {
		localStorage.setItem("subjectCache", JSON.stringify(subjectCache));
		return subjectCache;
	}
);

export const subjectCacheSlice = createSlice({
	name: "subjectCache",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setSubjectCache.fulfilled, (state, action) => {
			state.subjectCache = action.payload;
		});
	},
});

export default subjectCacheSlice.reducer;
