import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const starredNotes = JSON.parse(localStorage.getItem("starredNotes"));

const initialState = {
	starredNotes: starredNotes ? starredNotes : [],
};

export const setStarredNote = createAsyncThunk(
	"starredNotes/set",
	async (starredNotes, thunk) => {
		localStorage.setItem("starredNotes", JSON.stringify(starredNotes));
		return starredNotes;
	}
);

export const starredNotesSlice = createSlice({
	name: "starredNotes",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setStarredNote.fulfilled, (state, action) => {
			state.starredNotes = action.payload;
		});
	},
});

export default starredNotesSlice.reducer;
