import { Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ResetPass from "./components/ResetPass";
import NewPass from "./components/NewPass";
import SmoothScrollToTop from "./components/SmoothScrollToTop";
import Home from "./components/Home";
import Notes from "./components/Notes";
import MySpace from "./components/MySpace";
import Manage from "./components/Manage";
import UC from "./components/UC";
import PrivacyPolicy from "./components/PrivacyPolicy";
import "./style/css/reset.css";
import "./style/css/common.css";

import $ from "jquery";
import ResponseHelper from "./components/ResponseHelper";
import Preference from "./components/Preference";
import Upload from "./components/Upload";
import AdminPanel from "./components/AdminPanel";
import GenerateMAPIN from "./components/AdminPanel/GenerateMAPIN";
import MAPINs from "./components/AdminPanel/MAPINs";
import Users from "./components/AdminPanel/Users";
import ViewPDF from "./components/ViewPDF";
import Sidebar from "./components/Sidebar";
import Settings from "./components/Settings";
function App() {
	let deferredPrompt;

	// window.addEventListener("beforeinstallprompt", (e) => {
	// 	e.preventDefault();
	// 	deferredPrompt = e;
	// 	$("#install-prompt-container").show();
	// });

	// window.addEventListener("appinstalled", () => {
	// 	// $("#installed-prompt-container").show();
	// 	// $("#install-prompt-container").hide();
	// 	deferredPrompt = null;
	// });

	// function getPWADisplayMode() {
	// 	const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
	// 	if (document.referrer.startsWith('android-app://')) {
	// 		return 'twa';
	// 	} else if (navigator.standalone || isStandalone) {
	// 		return 1;
	// 	}
	// 	return 0;
	// }

	const showInstallationPrompt = async () => {
		// $("#install-prompt-container").hide();
		// deferredPrompt.prompt();
		// const { outcome } = await deferredPrompt.userChoice;
	};

	return (
		<div style={{ width: "100%" }}>
			<div id="install-prompt-container">
				<div id="install-prompt">
					<h2>Install Engineer Notes Web-App</h2>
					<p>
						To get even better experience install this site as a
						web-app
					</p>
					<div id="install-action-button-container">
						<button
							id="install-prompt-cancel-button"
							onClick={() => {
								$("#install-prompt-container").hide();
							}}
						>
							Cancel
						</button>
						<button
							id="install-prompt-install-button"
							onClick={showInstallationPrompt}
						>
							Install
						</button>
					</div>
				</div>
			</div>
			<div id="installed-prompt-container">
				<div id="installed-prompt">
					<h2>Installed Successfully</h2>
					<div id="installed-action-button-container">
						<button
							id="installed-prompt-continue-button"
							onClick={() => {
								$("#installed-prompt-container").hide();
							}}
						>
							Continue
						</button>
					</div>
				</div>
			</div>

			<Routes>
				<Route path="*" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgot-pass" element={<ResetPass />} />
				<Route path="/newpass" element={<NewPass />} />
				<Route path="/notes" element={<Notes />} />
				<Route path="/preference" element={<Preference />} />
				<Route path="/upload" element={<Upload />} />
				<Route path="/manage" element={<Manage />} />
				<Route path="/documentation" element={<UC />} />
				<Route path="/settings" element={<Settings />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/viewpdf" element={<ViewPDF />} />
				<Route path="/adminpanel" element={<AdminPanel />}>
					<Route index element={<Manage />} />
					<Route path="generatemapin" element={<GenerateMAPIN />} />
					<Route path="showmapin" element={<MAPINs />} />
					<Route path="showusers" element={<Users />} />
					<Route path="*" element={<Manage />} />
				</Route>
			</Routes>
			<SmoothScrollToTop />
			<ResponseHelper />
			<Sidebar />
		</div>
	);
}
export default App;
