import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice.js";
import preferenceReducer from "../features/preference/preferenceSlice.js";
import branchCacheReducer from "../features/branchCache/branchCacheSlice.js";
import instituteCacheReducer from "../features/instituteCache/instituteCacheSlice.js";
import subjectCacheReducer from "../features/subjectCache/subjectCacheSlice.js";
import notesCacheReducer from "../features/notesCache/notesCacheSlice.js";
import recentNotesReducer from "../features/recent/recentSlice.js";
import starredNotesReducer from "../features/starMark/starMarkSlice.js";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		preference: preferenceReducer,
		branchCache: branchCacheReducer,
		instituteCache: instituteCacheReducer,
		subjectCache: subjectCacheReducer,
		notesCache: notesCacheReducer,
		recents: recentNotesReducer,
		starredNotes: starredNotesReducer,
	},
});
