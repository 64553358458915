import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import showResponse from "../ResponseUtil";
import $ from "jquery";
const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
// const API_URL = "http://localhost:8080/api/";
const MAPINs = () => {
	const { user } = useSelector((state) => state.auth);
	const [mapins, setMAPINs] = useState([]);
	const API_HEADER_CONFIG = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	useEffect(() => {
		getMAPINs();
	}, [API_URL]);

	const getMAPINs = async () => {
		try {
			const response = await axios.get(
				API_URL + "getmapins",
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			const array = [];
			$.each(response.data, (_, mapin) => {
				array.push(mapin);
			});
			setMAPINs(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	return (
		<div className="selection-wrapper">
			{mapins.map((mapin, index) => {
				return (
					<div className="selection-option-row mapin-row" key={index}>
						<p>
							Name : <span>{mapin.name}</span>
						</p>
						<p>
							PIN : <span>{mapin.pin}</span>
						</p>
						<p>
							isMaster : <span>{mapin.isMaster + ""}</span>
						</p>
						<p>
							primaryBranch : <span>{mapin.primaryBranch}</span>
						</p>
						<p>
							primaryInstitute :{" "}
							<span>{mapin.primaryInstitute}</span>
						</p>
						<p>
							OtherBranchAllowed :{" "}
							<span>{mapin.otherBranch + ""}</span>
						</p>
						<p>
							OtherInstituteAllowed :{" "}
							<span>{mapin.otherInstitute + ""}</span>
						</p>
					</div>
				);
			})}
		</div>
	);
};
export default MAPINs;
