import "../style/css/ViewPDF.css";
import { useLocation } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { useLayoutEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import pdfIcon from "../img/pdf-file.jpg";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
const ViewPDF = () => {
	const pageReference = useRef();

	const location = useLocation();
	const { url, title } = location.state;
	// const url = "";
	// const title = "";
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageDimension, setPageDimension] = useState({ h: 0, w: 0 });
	useLayoutEffect(() => {
		setPageDimension({
			h: pageReference.current.clientHeight,
			w: pageReference.current.clientWidth,
		});
	}, []);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const prev = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	};
	const next = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	};
	return (
		<div className="component-container pdfview">
			<div id="pdfview-header">
				<img src={pdfIcon} alt="Pdf Icon" />
				<p>{title}</p>
				<a href={url} download>
					<FontAwesomeIcon icon={faDownload} />
				</a>
			</div>
			<div id="pdfview-document-container">
				<TransformWrapper>
					<TransformComponent>
						<Document
							inputRef={pageReference}
							className="pdfview-wrapper"
							file={url}
							options={{ workerSrc: "/pdf.worker.js" }}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Page
								renderAnnotationLayer={false}
								renderTextLayer={false}
								width={pageDimension.w}
								height={pageDimension.h}
								className="pdfview-page"
								pageNumber={pageNumber}
							/>
						</Document>
					</TransformComponent>
				</TransformWrapper>
			</div>
			<div className="component-wrapper pdfview-nav">
				<div className="root">
					<div id="pdfview-navigation">
						<button onClick={prev}>⬅</button>
						<p>
							{pageNumber}/{numPages}
						</p>
						<button onClick={next}>➡</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewPDF;
