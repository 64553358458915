import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
	faDownload,
	faEllipsisV,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../style/css/NoteModel.css";
import pdfIcon from "../img/pdf-file.jpg";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
const NoteModel = (
	index,
	note,
	url,
	shouldShowDeleteButton,
	starClickCallback,
	actionButtonToggleCallback,
	unStarClickCallback,
	recentClickCallback,
	suffix
) => {
	return (
		<div className="note" key={index}>
			<img src={pdfIcon} alt="Pdf Icon" />
			<Link
				to={"/viewpdf"}
				state={{ url: url, title: note.title }}
				onClick={recentClickCallback}
				className="note-title"
			>
				{note.title}
			</Link>

			<div
				className="note-action-container"
				id={`note-action-container-${suffix}${index}`}
			>
				<a
					onClick={() => {
						actionButtonToggleCallback();
						recentClickCallback();
					}}
					href={note.pdfUrl}
					download
				>
					<FontAwesomeIcon icon={faDownload} />
				</a>

				<a
					style={{
						display: shouldShowDeleteButton ? "none" : "flex",
					}}
					onClick={() => {
						actionButtonToggleCallback();
						starClickCallback();
					}}
				>
					<FontAwesomeIcon icon={faStar} />
				</a>

				<a
					style={{
						display: shouldShowDeleteButton ? "flex" : "none",
					}}
					onClick={() => {
						actionButtonToggleCallback();
						unStarClickCallback();
					}}
				>
					<FontAwesomeIcon icon={faTrash} />
				</a>
			</div>
			<button onClick={actionButtonToggleCallback}>
				<FontAwesomeIcon icon={faEllipsisV} />
			</button>
		</div>
	);
};

export default NoteModel;
