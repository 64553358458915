import "../style/css/AdminPanel.css";
import Toolbar from "./Toolbar";
import { NavLink, Outlet } from "react-router-dom";

const AdminPanel = () => {
	return (
		<div className="component-container adminpanel-container">
			{Toolbar("Admin Panel")}
			<div className="component-wrapper adminpanel-wrapper">
				<div className="root">
					<div id="adminpanel-container">
						<div id="adminpanel-tab-layout">
							<NavLink
								end={true}
								className={({ isActive }) =>
									isActive ? "active-adminpanel-tab" : ""
								}
								to=""
							>
								Home
							</NavLink>
							<NavLink
								className={({ isActive }) =>
									isActive ? "active-adminpanel-tab" : ""
								}
								to={"generatemapin"}
							>
								Generate MAPIN
							</NavLink>
							<NavLink
								className={({ isActive }) =>
									isActive ? "active-adminpanel-tab" : ""
								}
								to={"showmapin"}
							>
								MAPINs
							</NavLink>
							<NavLink
								className={({ isActive }) =>
									isActive ? "active-adminpanel-tab" : ""
								}
								to={"showusers"}
							>
								Users
							</NavLink>
						</div>
						<div id="adminpanel-fragment-container">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdminPanel;
