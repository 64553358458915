import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const notesCache = JSON.parse(localStorage.getItem("notesCache"));

const initialState = {
	notesCache: notesCache ? notesCache : [],
};

export const setNotesCache = createAsyncThunk(
	"notesCache/set",
	async (notesCache, thunk) => {
		localStorage.setItem("notesCache", JSON.stringify(notesCache));
		return notesCache;
	}
);

export const notesCacheSlice = createSlice({
	name: "notesCache",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setNotesCache.fulfilled, (state, action) => {
			state.notesCache = action.payload;
		});
	},
});

export default notesCacheSlice.reducer;
