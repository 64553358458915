import "../style/css/Sidebar.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { faBox, faHome, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Sidebar() {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	useEffect(() => {
		$("#manage-tab").hide();
		$("#myspace-tab").hide();

		if (user != null) {
			$("#myspace-tab").show();
			$("#mobile-sidebar-register-button").hide();
			$("#mobile-sidebar-username").text(user.name);
			if (user.role === 2) {
				$("#manage-tab").show();
			}
		} else {
			$("#mobile-sidebar-username").text("Guest");
			$("#mobile-sidebar-register-button").show();
		}
	}, [user]);

	const onMobileNavClick = () => {
		if ($("#menu-bar-mobile").is(":visible")) {
			$("#sidebar").toggleClass("show-sidebar");
			if ($("#sidebar").is(":visible")) {
				$("#overlay-background-mobile-sidebar").show();
			} else {
				$("#overlay-background-mobile-sidebar").hide();
			}
		}
	};
	const onRegistration = () => {
		onMobileNavClick();
		navigate("/register");
	};

	return (
		<div id="sidebar">
			<div id="mobile-sidebar-auth-container">
				<div id="mobie-sidebar-user-info">
					<div id="user-profile-pic">
						<img src="img/user.jpg" alt="User profile" />
					</div>
					<p id="mobile-sidebar-username"></p>
				</div>
			</div>
			<div id="navigation-container">
				<NavLink
					onClick={onMobileNavClick}
					to="/"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faHome} /> <span>Home</span>
				</NavLink>

				<NavLink
					onClick={onMobileNavClick}
					to="/notes"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faStickyNote} /> <span>Notes</span>
				</NavLink>

				{/* <NavLink
					to="/news"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faRss} /> <span>News</span>
				</NavLink>
				<NavLink
					to="/time-table"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faCalendarDay} /> <span>Time Tables</span>
				</NavLink>
				<NavLink
					to="/pyq"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faCopy} /> <span>PYQs</span>
				</NavLink> */}
				{/* <NavLink
					onClick={onMobileNavClick}
					id="myspace-tab"
					to="/myspace"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faBox} /> <span>My Space</span>
				</NavLink> */}
				<NavLink
					onClick={onMobileNavClick}
					id="manage-tab"
					to="/manage"
					className={({ isActive }) =>
						isActive ? "nav-active nav-link" : "nav-link"
					}
				>
					<FontAwesomeIcon icon={faBox} /> <span>Manage</span>
				</NavLink>
				<div id="mobile-sidebar-other-navigation">
					<p>Others</p>
					<NavLink
						onClick={onMobileNavClick}
						className={({ isActive }) =>
							isActive
								? "other-navigation other-navigation-active"
								: "other-navigation"
						}
						to={"/documentation"}
					>
						Documentation
					</NavLink>
					{/* <NavLink
						onClick={onMobileNavClick}
						className={({ isActive }) =>
							isActive
								? "other-navigation other-navigation-active"
								: "other-navigation"
						}
						to={"/about"}
					>
						About
					</NavLink> */}
					{/* <NavLink
						onClick={onMobileNavClick}
						className={({ isActive }) =>
							isActive
								? "other-navigation other-navigation-active"
								: "other-navigation"
						}
						to={"/policy"}
					>
						Privacy Policy
					</NavLink> */}
				</div>
			</div>
			<div id="mobile-sidebar-other-info">
				<hr />
				<div id="mobile-sidebar-register-button">
					<button onClick={onRegistration}>Register Now</button>
				</div>
				<p id="copyright-label">
					Copyright © 2022 <br />
					EngineerNotes
				</p>
			</div>
		</div>
	);
}

export default Sidebar;
