import { useDispatch, useSelector } from "react-redux";
import { setBranchCache } from "../features/branchCache/branchCacheSlice";
import { setInstituteCache } from "../features/instituteCache/instituteCacheSlice";
import { setSubjectCache } from "../features/subjectCache/subjectCacheSlice";
import "../style/css/Settings.css";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
import showResponse from "./ResponseUtil";
import Toolbar from "./Toolbar";
import $ from "jquery";
import axios from "axios";

const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;

const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";

// const API_URL = "http://localhost:8080/api/";
const Settings = () => {
	const dispatch = useDispatch();
	let { preference } = useSelector((state) => state.preference);

	const branch = preference.branch ? preference.branch : "";
	const semester = preference.semester ? preference.semester : "";
	const refreshSubjects = async () => {
		let data = {
			branchCode: branch,
			semester: semester,
		};
		try {
			showProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			const response = await axios.post(API_URL + "subjects", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				hideProgressbar(
					"#toolbarProgressbar",
					"#toolbar-action-button"
				);
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push({
					title: value.title,
					code: value.code,
				});
			});
			dispatch(setSubjectCache(array));
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			showResponse(TYPE_SUCCESS, "Subjects refreshed successfully", 2500);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				2500
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
		}
	};
	const refreshBranch = () => {
		getData("branches");
	};
	const refreshInstitute = () => {
		getData("institutes");
	};
	const getData = async (type) => {
		try {
			showProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			const response = await axios.get(API_URL + type);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");

			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push({
					title: value.title,
					code: value.code,
				});
			});
			switch (type) {
				case "branches":
					dispatch(setBranchCache(array));
					showResponse(
						TYPE_SUCCESS,
						"Branches refreshed successfully",
						2500
					);

					break;
				case "institutes":
					dispatch(setInstituteCache(array));
					showResponse(
						TYPE_SUCCESS,
						"Institutes refreshed successfully",
						2500
					);

					break;
			}
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				2500
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
		}
	};
	return (
		<div className="component-container">
			{Toolbar("Settings")}
			<div className="component-wrapper">
				<div className="root">
					<div id="settings-container">
						<div className="selection-wrapper">
							<div
								onClick={refreshBranch}
								className="selection-option-row"
							>
								<p className="setting-label">Refresh branch</p>
								<div
									className="progressbar"
									id="refreshBranchProgressbar"
								></div>
							</div>
							<div
								onClick={refreshInstitute}
								className="selection-option-row"
							>
								<p className="setting-label">
									Refresh institutes
								</p>
								<div
									className="progressbar"
									id="refreshInstituteProgressbar"
								></div>
							</div>
							<div
								onClick={refreshSubjects}
								className="selection-option-row"
							>
								<p className="setting-label">
									Refresh subjects
								</p>
								<div
									className="progressbar"
									id="refreshSubjectProgressbar"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Settings;
