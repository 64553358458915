import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const instituteCache = JSON.parse(localStorage.getItem("instituteCache"));

const initialState = {
	instituteCache: instituteCache ? instituteCache : [],
};

export const setInstituteCache = createAsyncThunk(
	"instituteCache/set",
	async (instituteCache, thunk) => {
		localStorage.setItem("instituteCache", JSON.stringify(instituteCache));
		return instituteCache;
	}
);

export const instituteCacheSlice = createSlice({
	name: "instituteCache",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setInstituteCache.fulfilled, (state, action) => {
			state.instituteCache = action.payload;
		});
	},
});

export default instituteCacheSlice.reducer;
