import "../style/css/Manage.css";
import "../style/css/form.css";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
// const API_URL = "http://localhost:8080/api/";
const Manage = () => {
	const TYPE_ERROR = 0;
	const TYPE_SUCCESS = 1;

	const [formData, setData] = useState({
		name: "",
		role: "0",
	});

	const [mapinData, setMapinData] = useState([]);
	const [userData, setUserData] = useState([]);
	const [subjectList, setSubjectList] = useState([]);
	const [unitList, setUnitList] = useState([]);
	const [branchList, setBranchList] = useState([]);

	const [subjectListForCopy, setSubjectListForCopy] = useState([]);

	const [cpSubject, setCPSubject] = useState("");
	const [cpSubjectTitle, setCPSubjectTitle] = useState("");

	const { name, role } = formData;
	const { user } = useSelector((state) => state.auth);
	const API_HEADER_CONFIG = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	const [subjectData, setSubjectData] = useState({
		sTitle: "",
		semester: "",
		branchCode: "",
	});
	const { sTitle, semester, branchCode } = subjectData;

	const [unitData, setUnitData] = useState({
		unitTitle: "",
		subjectCode: "",
	});
	const { unitTitle, subjectCode } = unitData;
	const [branchData, setBranchData] = useState({
		branchTitle: "",
	});
	const { branchTitle } = branchData;

	// useEffect(() => {
	// 	if (user != null) {
	// 		if (user.role === 2) {
	// 			$("#container").show();
	// 		} else {
	// 			$("#container").hide();
	// 		}
	// 	} else {
	// 		$("#container").hide();
	// 	}
	// }, [user]);

	const onChange = (e) => {
		setData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const onChangeSubjectForm = (e) => {
		setSubjectData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const onChangeUnitForm = (e) => {
		setUnitData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const onChangeBranchForm = (e) => {
		setBranchData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};
	const onGiveAccess = () => {
		if (name === "") {
			showResponse(TYPE_ERROR, "Name should not be empty");
			return;
		}

		const accessData = {
			name,
			role,
		};

		axios
			.post(API_URL + "admin/give-access", accessData, API_HEADER_CONFIG)
			.then((res) => {
				showResponse(TYPE_SUCCESS, "Access updated successfully");
				onGetMapins();
			})
			.catch((error) => {
				showResponse(
					TYPE_ERROR,
					error.response.data
						? error.response.data.message
						: error.message
				);
			});
	};
	const onRestrictAccess = (id) => {
		const accessData = {
			id,
		};

		axios
			.post(
				API_URL + "admin/restrict-access",
				accessData,
				API_HEADER_CONFIG
			)
			.then((res) => {
				showResponse(TYPE_SUCCESS, "Access updated successfully");
				onGetMapins();
			})
			.catch((error) => {
				showResponse(
					TYPE_ERROR,
					error.response.data
						? error.response.data.message
						: error.message
				);
			});
	};
	const onGetMapins = () => {
		axios
			.get(API_URL + "admin/mapins", API_HEADER_CONFIG)
			.then((res) => {
				let array = [];
				$.each(res.data, (_, value) => {
					array.push(value);
				});
				setMapinData(array);
			})
			.catch((error) => {
				showResponse(
					TYPE_ERROR,
					error.response.data
						? error.response.data.message
						: error.message
				);
			});
	};

	const showResponse = (type, error) => {
		let background = "#f13031";
		if (type === 1) {
			background = "#2BB772";
		}
		$("#response-div").css("background", background);
		$("#response-div").show();
		$("#response-div").text(error);
		hideResponseAfterDelay();
	};
	const hideResponseAfterDelay = () => {
		setTimeout(() => {
			$("#response-div").hide();
		}, 5000);
	};

	const onDeleteUser = async (user) => {
		if (user.role === 2) {
			showResponse(TYPE_ERROR, "Master admin can't be deleted");
			return;
		}
		let userInfo = {
			id: user._id,
			role: user.role,
		};

		try {
			const response = await axios.post(
				API_URL + "admin/delete-user",
				userInfo,
				API_HEADER_CONFIG
			);
			showResponse(TYPE_SUCCESS, "Deleted user successfully");
			onGetUsers();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const onGetUsers = async () => {
		try {
			const response = await axios.get(
				API_URL + "admin/get-users",
				API_HEADER_CONFIG
			);
			showResponse(TYPE_SUCCESS, "User fetch successfully");
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setUserData(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const setSubject = async () => {
		if (sTitle === "" && cpSubjectTitle === "") {
			showResponse(TYPE_ERROR, "Title should not be empty");
			return;
		}
		if (semester === "") {
			showResponse(TYPE_ERROR, "Semester should not be empty");
			return;
		}

		let subject = {
			subject: cpSubjectTitle != "" ? cpSubjectTitle : sTitle,
			semester: semester,
			branchCode: branchCode,
			subjectCode: cpSubject,
		};

		try {
			const response = await axios.post(
				API_URL + "admin/set-subject",
				subject,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, response.data);
			getSubjects();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const deleteSubject = async (code) => {
		let data = {
			code: code,
		};
		try {
			let response = await axios.post(
				API_URL + "admin/delete-subject",
				data,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, " Deleted successfully");
			getSubjects();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const setUnit = async () => {
		if (unitTitle === "") {
			showResponse(TYPE_ERROR, "Title should not be empty");
			return;
		}
		if (subjectCode === "") {
			showResponse(TYPE_ERROR, "Subject code should not be empty");
			return;
		}
		let unit = {
			subjectCode: subjectCode,
			unitTitle: unitTitle,
		};

		try {
			const response = await axios.post(
				API_URL + "admin/set-unit",
				unit,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}

			showResponse(TYPE_SUCCESS, response.data);
			getUnits();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const deleteUnit = async (code) => {
		let data = {
			code: code,
		};
		try {
			let response = await axios.post(
				API_URL + "admin/delete-unit",
				data,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, " Deleted successfully");
			getUnits();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const deleteBranch = async (code) => {
		let data = {
			code: code,
		};
		try {
			let response = await axios.post(
				API_URL + "admin/delete-branch",
				data,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, " Deleted successfully");
			getBranches();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const setBranch = async () => {
		if (branchTitle === "") {
			showResponse(TYPE_ERROR, "Title should not be empty");
			return;
		}

		let branch = {
			branch: branchTitle,
		};

		try {
			const response = await axios.post(
				API_URL + "admin/set-branch",
				branch,
				API_HEADER_CONFIG
			);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, response.data);
			getBranches();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const getBranches = async () => {
		try {
			const response = await axios.get(API_URL + "branches");
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setBranchList(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const getSubjects = async () => {
		let data = {
			semester: semester,
			branchCode: branchCode,
		};
		try {
			const response = await axios.post(API_URL + "subjects", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setSubjectList(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const getSubjectsForCopy = async (branch) => {
		let data = {
			semester: semester,
			branchCode: branch,
		};
		try {
			const response = await axios.post(API_URL + "subjects", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setSubjectListForCopy(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};
	const onCPSubjectChange = (event) => {
		setCPSubject(event.target.value);
		setCPSubjectTitle(
			event.target.options[event.target.selectedIndex].text
		);
	};
	const getUnits = async () => {
		let data = {
			subjectCode: subjectCode,
		};
		try {
			const response = await axios.post(API_URL + "units", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setUnitList(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
		}
	};

	const onBranchForCopyChanged = (event) => {
		getSubjectsForCopy(event.target.value);
	};

	useEffect(() => {
		onGetMapins();
		onGetUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="container">
			<div id="admin-manage-wrapper">
				<p id="response-div"></p>
				<h2 id="header">Manage Admin</h2>
				<div id="admin-manage-form-container">
					<div className="input-field">
						<input
							name="name"
							type="text"
							className="input"
							value={name}
							onChange={onChange}
							placeholder="Name of user"
						/>
					</div>
					<div className="input-field">
						<select
							name="role"
							className="input"
							value={role}
							onChange={onChange}
						>
							<option value="0">Student</option>
							<option value="1">Faculty</option>
						</select>
					</div>

					<button onClick={onGiveAccess}>Give Access</button>
					<button onClick={onGetMapins}>Get</button>
				</div>
				<div id="admin-pin-list-container">
					{mapinData.map((mapin, index) => {
						return (
							<div
								key={index}
								className="pin-row"
								onClick={() => onRestrictAccess(mapin._id)}
							>
								<p>
									<span>{mapin.pin}</span>
									<br />
									{mapin.name}
								</p>
							</div>
						);
					})}

					{/* <div className="pin-row">
						<p>
							<span>123346</span>
							<br />
							Gaurav Saini
						</p>
					</div> */}
				</div>
				<div id="user-list-container">
					{userData.map((user, index) => {
						return (
							<div
								onClick={() => onDeleteUser(user)}
								className="user"
								key={index}
							>
								<p>{user.name}</p>
							</div>
						);
					})}
					{/* <div className="user">
						<p>Gaurav Saini</p>
					</div> */}
				</div>
			</div>
			<div id="subject-unit-container">
				<div id="branch-container">
					<div id="branch-submit-form">
						<h2 className="form-header">Upload Branch</h2>
						<div className="input-field">
							<input
								type="text"
								className="input"
								name="branchTitle"
								value={branchTitle}
								placeholder="Title"
								onChange={onChangeBranchForm}
							/>
						</div>

						<div className="input-field">
							<button
								className="submit-button"
								onClick={() => setBranch()}
							>
								Submit
							</button>
							<button
								className="submit-button"
								onClick={() => getBranches()}
							>
								Get
							</button>
							<div className="spinner-wrapper" id="loading">
								<div className="spinner">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div id="branch-wrapper">
						{branchList.map((branch, index) => {
							return (
								<div
									onClick={() => deleteBranch(branch.code)}
									className="branch"
									key={index}
								>
									<p>{branch.title}</p>
								</div>
							);
						})}
					</div>
				</div>
				<div id="subject-container">
					<div id="subject-submit-form">
						<h2 className="form-header">Upload Subject</h2>
						<div className="input-field">
							<input
								type="text"
								className="input"
								name="sTitle"
								value={sTitle}
								placeholder="Title"
								onChange={onChangeSubjectForm}
							/>
						</div>
						<div className="input-field">
							<select
								name="branchCode"
								className="input"
								onChange={onBranchForCopyChanged}
							>
								<option value={0}>Copy Branch</option>
								{branchList.map((branch, index) => {
									return (
										<option key={index} value={branch.code}>
											{branch.title}
										</option>
									);
								})}
							</select>
						</div>
						<div className="input-field">
							<select
								className="input"
								onChange={onCPSubjectChange}
							>
								<option value="">Copy Subject</option>

								{subjectListForCopy.map((subject, index) => {
									return (
										<option
											key={index}
											value={subject.code}
										>
											{subject.title}
										</option>
									);
								})}
							</select>
						</div>
						<div className="input-field">
							<select
								name="branchCode"
								className="input"
								value={branchCode}
								onChange={onChangeSubjectForm}
							>
								<option value={0}>Select Branch</option>
								{branchList.map((branch, index) => {
									return (
										<option key={index} value={branch.code}>
											{branch.title}
										</option>
									);
								})}
							</select>
						</div>
						<div className="input-field">
							<input
								type="number"
								className="input"
								name="semester"
								value={semester}
								placeholder="Semester"
								onChange={onChangeSubjectForm}
							/>
						</div>
						<div className="input-field">
							<button
								className="submit-button"
								onClick={() => setSubject()}
							>
								Submit
							</button>
							<button
								className="submit-button"
								onClick={() => getSubjects()}
							>
								Get
							</button>
							<div className="spinner-wrapper" id="loading">
								<div className="spinner">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div id="subject-list-container">
						<div id="subjects-wrapper">
							{subjectList.map((subject, index) => {
								return (
									<div
										className="subject"
										onClick={() =>
											deleteSubject(subject.code)
										}
										key={index}
									>
										<p>{subject.title}</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div id="unit-container">
					<div id="unit-submit-form">
						<h2 className="form-header">Upload Unit</h2>
						<div className="input-field">
							<input
								type="text"
								className="input"
								name="unitTitle"
								value={unitTitle}
								placeholder="Title"
								onChange={onChangeUnitForm}
							/>
						</div>
						<div className="input-field">
							<select
								name="subjectCode"
								className="input"
								value={subjectCode}
								onChange={onChangeUnitForm}
							>
								<option value={0}>Select Subject</option>
								{subjectList.map((subject, index) => {
									return (
										<option
											key={index}
											value={subject.code}
										>
											{subject.title}
										</option>
									);
								})}
							</select>
						</div>
						<div className="input-field">
							<button
								className="submit-button"
								onClick={() => setUnit()}
							>
								Submit
							</button>
							<button
								className="submit-button"
								onClick={() => getUnits()}
							>
								Get
							</button>
							<div className="spinner-wrapper" id="loading">
								<div className="spinner">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
					<div id="units-wrapper">
						{unitList.map((unit, index) => {
							return (
								<div
									onClick={() => deleteUnit(unit.code)}
									className="unit"
									key={index}
								>
									<p>{unit.title}</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Manage;
