import $ from "jquery";
export const showProgressbar = (progressbar, element) => {
	$(progressbar).show();
	if (element) {
		$(element).hide();
	}
};

export const hideProgressbar = (progressbar, element) => {
	$(progressbar).hide();
	if (element) {
		$(element).show();
	}
};
