import $ from "jquery";
let responseTimer;
let responseTimeout;
const showResponse = (type, message, duration = 10000) => {
	let background = "#f13031";
	if (type === 1) {
		background = "#2BB772";
	}

	clearInterval(responseTimer);
	let timeout = duration / 1000;
	responseTimer = setInterval(
		(function f() {
			if (timeout < 1) {
				clearInterval(responseTimer);
			}
			return f;
		})(),
		1000
	);
	$("#response").css("background", background);
	$("#response-container").css("display", "flex");
	$("#response-message").text(message);
	$("#response-overlay").stop();
	$("#response-overlay").css("width", "100%");
	$("#response-overlay").animate(
		{
			width: 0,
		},
		duration
	);

	hideResponseAfterDelay(duration);
};
const hideResponseAfterDelay = (duration) => {
	clearTimeout(responseTimeout);
	responseTimeout = setTimeout(() => {
		$("#response-container").hide();
	}, duration);
};

export const hideResponse = () => {
	clearTimeout(responseTimeout);
	$("#response-container").hide();
};

export default showResponse;
