import "../style/css/Auth.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faCheckCircle,
	faRemove,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

let errorTimer;
let errorTimeout;
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
const ResetPass = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		email: "",
	});

	const { email } = formData;

	const showProgressbar = (progressbar, element) => {
		$(progressbar).show();
		if (element) {
			$(element).hide();
		}
	};

	const hideProgressbar = (progressbar, element) => {
		$(progressbar).hide();
		if (element) {
			$(element).show();
		}
	};

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (email === "") {
			showError("Email is required");
			return;
		}
		const data = {
			email: email,
			token: Math.random().toString(36).slice(2),
		};
		try {
			showProgressbar("#resetSubmitProgressBar", "#reset-submit-button");
			let response = await axios.post(API_URL + "passresettoken/add", data);
			hideProgressbar("#resetSubmitProgressBar", "#reset-submit-button");
			if (!response) {
				showError("Something went wrong");
				return;
			}
			$("#reset-mail-sent").show();
			$("#reset-password-form").hide();
		} catch (error) {
			hideProgressbar("#resetSubmitProgressBar", "#reset-submit-button");
			showError(error.message);
		}
	};

	const showError = (message) => {
		clearInterval(errorTimer);
		let timeout = 5;
		errorTimer = setInterval(
			(function f() {
				if (timeout < 1) {
					clearInterval(errorTimer);
				}
				return f;
			})(),
			1000
		);
		$("#resetpass-error-div").show();
		$("#resetpass-error-message").text(message);

		hideResponseAfterDelay();
	};
	const hideResponseAfterDelay = () => {
		clearTimeout(errorTimeout);
		errorTimeout = setTimeout(() => {
			$("#resetpass-error-div").hide();
		}, 5000);
	};
	$("#resetpass-error-remove-button").on("click", () => {
		clearTimeout(errorTimeout);
		$("#resetpass-error-div").hide();
	});
	/* RESPONSE ------------------*/
	return (
		<>
			<div className="error-div" id="resetpass-error-div">
				<p className="error-message" id="resetpass-error-message"></p>
				<p
					onClick={() => {
						$("#resetpass-error-div").hide();
					}}
					className="error-remove-button"
					id="resetpass-error-remove-button"
				>
					<FontAwesomeIcon icon={faRemove} />
				</p>
			</div>
			<div className="form-container">
				<div className="form-wrapper">
					<div className="auth-page-info-wrapper">
						<button
							onClick={() => {
								navigate("/login");
							}}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> Back
						</button>
						<h2>Reset your account password</h2>
						<p>
							A mail will be sent to your registered email address to help you
							change your account password.
						</p>
					</div>
					<div className="auth-form">
						<h2 className="auth-heading">Reset Password</h2>
						<p id="reset-mail-sent">
							<span>
								<FontAwesomeIcon icon={faCheckCircle} />
							</span>
							<br />
							<br />
							Password reset email sent successfully, If account exist you will
							receive it shortly.
						</p>
						<div id="reset-password-form">
							<div className="input-field">
								<p className="input-label">Email</p>
								<input
									className="input-box"
									type="email"
									name="email"
									value={email}
									onChange={onChange}
								/>
							</div>

							<input
								onClick={onSubmit}
								className="input-box submit-button"
								id="reset-submit-button"
								type="submit"
							/>
							<div className="progressbar" id="resetSubmitProgressBar"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResetPass;
