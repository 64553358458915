import "../style/css/Toolbar.css";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { useEffect } from "react";
const USE_EFFECT = 0;
const Toolbar = (title, actionTitle, toolbarActionCallback) => {
	useEffect(() => {
		if (!toolbarActionCallback) $("#toolbar-action-button").hide();
	}, [USE_EFFECT]);

	const navigate = useNavigate();
	const onBackPressed = () => {
		navigate(-1);
	};
	return (
		<div className="component-wrapper toolbar">
			<div className="root">
				<div id="toolbar-container">
					<p id="back-button" onClick={onBackPressed}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</p>
					<p id="toolbar-title">{title}</p>
					<div id="toolbar-progressbar-wrapper">
						<div
							className="progressbar"
							id="toolbarProgressbar"
						></div>
					</div>
					<a
						id="toolbar-action-button"
						onClick={toolbarActionCallback}
					>
						{actionTitle}
					</a>
				</div>
			</div>
		</div>
	);
};

export default Toolbar;
