/* eslint-disable jsx-a11y/anchor-is-valid */
import "../style/css/Notes.css";
import "../style/css/form.css";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Toolbar from "./Toolbar";
import SearchIcon from "../img/search.png";
import showResponse from "./ResponseUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSliders } from "@fortawesome/free-solid-svg-icons";
import NoteModel from "./NoteModel";
import { showProgressbar, hideProgressbar } from "./ProgressbarHelper";
import { setRecents } from "../features/recent/recentSlice";
import { setStarredNote } from "../features/starMark/starMarkSlice";
const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;

const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
// const API_URL = "http://localhost:8080/api/";

const Notes = () => {
	const dispatch = useDispatch();

	const { subjectCache } = useSelector((state) => state.subjectCache);
	const { notesCache } = useSelector((state) => state.notesCache);
	const { instituteCache } = useSelector((state) => state.instituteCache);

	let { preference } = useSelector((state) => state.preference);

	const institutePreference = preference.institute
		? preference.institute
		: "";

	const [subject, setSubject] = useState("");
	const [notes, setNotes] = useState([]);

	const onSubjectChange = (event) => {
		setSubject(event.target.value);
	};

	const onInstituteFilterChange = (event) => {
		let institute = event.target.value;
		if (institute == institutePreference) {
			getCachedNotes();
		} else {
			getNotes();
		}
	};
	const handleEmptyResult = (length) => {
		if (length < 1 && $("#subject-select").val() != "-1") {
			$(".no-result-warning").show();
		} else {
			$(".no-result-warning").hide();
		}
		if ($("#subject-select").val() == "-1") {
			$(".start-search-warning").show();
		} else {
			$(".start-search-warning").hide();
		}
	};
	const getCachedNotes = () => {
		const noteResult = $.grep(notesCache, (note) => {
			return note.subject === subject;
		});
		setNotes(noteResult);
	};
	useEffect(() => {
		handleEmptyResult(notes.length);
	}, [notes]);
	useEffect(() => {
		getCachedNotes();
	}, [subject]);

	// const onNoteDeleteRequest = async (id, fileName, title) => {
	// 	let data = {
	// 		id: id,
	// 		pdfFileName: fileName,
	// 	};

	// 	try {
	// 		const response = await axios.post(API_URL + "note/delete", data);

	// 		if (!response) {
	// 			showResponse(TYPE_ERROR, "Something went wrong");
	// 			return;
	// 		}
	// 		let historyMessage =
	// 			branchTitle +
	// 			" • " +
	// 			semesterTitle +
	// 			" • " +
	// 			subjectTitle +
	// 			" • " +
	// 			title;
	// 		submitHistory(id, 0, "NA", historyMessage);
	// 		updateHistoryActionAllowed(id);
	// 		showResponse(TYPE_SUCCESS, response.data);
	// 		getNotes();
	// 	} catch (error) {
	// 		showResponse(
	// 			TYPE_ERROR,
	// 			error.response.data
	// 				? error.response.data.message
	// 				: error.message
	// 		);
	// 	}
	// };

	const getNotes = async () => {
		if (subject == "" || institutePreference == "") {
			showResponse(TYPE_ERROR, "Subject or institute not selected", 4000);
			return;
		}
		let data = {
			subject: subject,
			institute: institutePreference,
		};
		try {
			showProgressbar("#notesProgressbar");
			const response = await axios.post(API_URL + "notes", data);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				hideProgressbar("#notesProgressbar");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			setNotes(array);
			hideProgressbar("#notesProgressbar");
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
			hideProgressbar("#notesProgressbar");
		}
	};
	let starredNotes = useSelector((state) => state.starredNotes.starredNotes);
	let recents = useSelector((state) => state.recents.recents);

	const openNote = () => {};
	const downloadNote = () => {};
	const onStarClick = (note) => {
		const array = [];
		let shouldStarMark = true;
		$.each(starredNotes, (_, starredNote) => {
			if (starredNote._id == note._id) {
				shouldStarMark = false;
			} else {
				array.push(starredNote);
			}
		});
		if (!shouldStarMark) return;
		array.unshift(note);
		dispatch(setStarredNote(array));
		onRecentClick(note);
	};
	const onRecentClick = (note) => {
		const array = [];
		$.each(recents, (index, recent) => {
			if (index > 10) {
				return;
			}
			if (recent._id != note._id) {
				array.push(recent);
			}
		});
		array.unshift(note);
		dispatch(setRecents(array));
	};

	const onUnstarClick = (note) => {
		const array = [];
		$.each(starredNotes, (_, starredNote) => {
			if (starredNote._id != note._id) {
				array.push(starredNote);
			}
		});
		dispatch(setStarredNote(array));
	};

	useEffect(() => {
		// console.log(starredNotes);
	}, [starredNotes]);
	const actionButtonToggle = (index) => {
		const element = $("#note-action-container-" + index);
		if (element.is(":visible")) {
			$(".note-action-container").hide();
		} else {
			$(".note-action-container").hide();
			element.show();
		}
	};
	return (
		<div className="component-container notes">
			{Toolbar("Explore notes")}
			<div className="component-wrapper notes-wrapper">
				<div className="root">
					<div id="notes-container">
						<div id="search-container">
							<div id="searchbar-container">
								<div id="searchbar-wrapper">
									<FontAwesomeIcon
										icon={faSearch}
										id="search-icon"
									/>
									<select
										id="subject-select"
										onChange={onSubjectChange}
									>
										<option value="-1">
											Select Subject
										</option>

										{subjectCache.map((subject, index) => {
											return (
												<option
													key={index}
													value={subject.code}
												>
													{subject.title}
												</option>
											);
										})}
									</select>
								</div>
							</div>
							<div id="filter-container">
								<p id="filter-label">
									<FontAwesomeIcon
										icon={faSliders}
										id="filter-icon"
									/>{" "}
									Filter
								</p>
								<select
									id="institute-select"
									onChange={onInstituteFilterChange}
									defaultValue={institutePreference}
								>
									{instituteCache.map((institute, index) => {
										return (
											<option
												key={index}
												value={institute.code}
											>
												{institute.title}
											</option>
										);
									})}
								</select>
							</div>
							<div id="result-container">
								<div id="notes-progressbar-container">
									<div
										className="progressbar"
										id="notesProgressbar"
									></div>
								</div>
								<div className="no-result-warning-container">
									<p className="no-result-warning">
										No result found for{" "}
										<span>
											'
											{$(
												"#subject-select option:selected"
											).text()}
											'
										</span>{" "}
										under{" "}
										<span>
											'
											{$(
												"#institute-select option:selected"
											).text()}
											'
										</span>
									</p>
									<p className="start-search-warning">
										<img
											src={SearchIcon}
											alt="Search Icon"
										/>{" "}
										<br />
										Select subject to start searching
									</p>
								</div>
								{notes.map((note, index) => {
									return NoteModel(
										index,
										note,
										note.pdfUrl,
										false,
										() => onStarClick(note),
										() => actionButtonToggle(index),
										() => onUnstarClick(note),
										() => onRecentClick(note),
										""
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Notes;
