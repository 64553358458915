import "../style/css/Header.css";
import { logout, reset } from "../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import $ from "jquery";
import NavigationHeader from "./NavigationHeader";

function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	useEffect(() => {
		if (user == null) {
			$("#logout-button").hide();
			$("#login-button").show();
			$("#register-button").show();
			$("#username").text("Guest");
		} else {
			$("#logout-button").show();
			$("#login-button").hide();
			$("#register-button").hide();
			$("#username").text(user.name);
		}
		dispatch(reset());
	}, [user, dispatch]);
	const onLogout = () => {
		dispatch(logout());
		dispatch(reset());
		navigate("/");
	};

	const onRegistration = () => {
		navigate("/register");
	};

	const onLogin = () => {
		navigate("/login");
	};

	// useEffect(() => {
	// 	let hours = new Date().getHours();
	// 	let greeting = "Hello";
	// 	let src = "img/goodmorning.jpg";
	// 	if (hours >= 4 && hours < 12) {
	// 		greeting = "Good Morning";
	// 		src = "img/goodmorning.jpg";
	// 	} else if (hours >= 12 && hours <= 17) {
	// 		greeting = "Good Afternoon";
	// 		src = "img/goodafternoon.jpg";
	// 	} else if (hours >= 17 && hours <= 22) {
	// 		greeting = "Good Evening";
	// 		src = "img/goodevening.jpg";
	// 	} else if (hours >= 0 && hours < 3) {
	// 		greeting = "Good Night";
	// 		src = "img/goodnight.jpg";
	// 	} else if (hours == 3) {
	// 		greeting = "Early Morning";
	// 		src = "img/earlymorning.jpg";
	// 	}
	// 	$("#greeting-message").text(greeting);
	// 	$("#greeting-image").attr("src", src);
	// }, []);
	return (
		<>
			<div className="component-wrapper header">
				<div className="root">
					<div id="header-container">
						<NavigationHeader />

						<div id="nav-welcome">
							<div id="navigation-links">
								<NavLink
									to={"/"}
									className={({ isActive }) =>
										isActive
											? "other-navigation other-navigation-active hide"
											: "other-navigation"
									}
								>
									Home
								</NavLink>
								<NavLink
									className={({ isActive }) =>
										isActive
											? "other-navigation other-navigation-active"
											: "other-navigation"
									}
									to={"/documentation"}
								>
									Documentation
								</NavLink>
								{/* <NavLink
            className={({ isActive }) =>
                isActive
                    ? "other-navigation other-navigation-active"
                    : "other-navigation"
            }
            to={"/about"}
        >
            About
        </NavLink> */}
							</div>
							<div id="user-info-wrapper">
								<div id="user-profile-wrapper">
									<img
										src="img/user.jpg"
										alt="User profile"
									/>
								</div>
								<br />
								<p id="username"></p>
							</div>
						</div>
						<div id="nav-control-wrapper">
							<button
								id="login-button"
								className="nav-control-buttons"
								onClick={onLogin}
							>
								Login
							</button>
							<button
								id="register-button"
								className="nav-control-buttons"
								onClick={onRegistration}
							>
								Register Account
							</button>
							<button
								id="logout-button"
								className="nav-control-buttons"
								onClick={onLogout}
							>
								Logout
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Header;
