import { faClock, faFont, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../style/css/Home.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import showResponse from "./ResponseUtil";
import $ from "jquery";
import axios from "axios";
import Header from "./Header";
import NoteModel from "./NoteModel";

import { setBranchCache } from "../features/branchCache/branchCacheSlice";
import { setInstituteCache } from "../features/instituteCache/instituteCacheSlice";
import { setPreference } from "../features/preference/preferenceSlice";
import { setNotesCache } from "../features/notesCache/notesCacheSlice";
import { setStarredNote } from "../features/starMark/starMarkSlice";

const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
// const API_URL = "http://localhost:8080/api/";

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { recents } = useSelector((state) => state.recents);
	const { starredNotes } = useSelector((state) => state.starredNotes);
	let { preference } = useSelector((state) => state.preference);
	const { user } = useSelector((state) => state.auth);

	useEffect(
		(_) => {
			$("#recent-tab").trigger("click");
			verifySession();
		},
		[API_URL]
	);

	const verifySession = () => {
		if (user) {
		}
	};

	const onTabChange = (e) => {
		$(".tab").each(function () {
			$(this).removeClass("active-tab");
		});
		$(e.target).addClass("active-tab");
		onFragmentChange(e.target.id);
	};

	const onFragmentChange = (tabId) => {
		$(".tab-fragment").each(function () {
			$(this).removeClass("active-fragment");
		});
		$("#" + tabId + "-fragment").addClass("active-fragment");
	};

	const { branchCache } = useSelector((state) => state.branchCache);
	const { instituteCache } = useSelector((state) => state.instituteCache);

	const cacheBranches = () => {
		if (branchCache.length > 0) return;
		getData("branches");
	};

	const cacheInstitutes = () => {
		if (instituteCache.length > 0) return;
		getData("institutes");
	};

	const branchTitle = preference.branchTitle
		? preference.branchTitle
		: "Select branch";
	const semesterTitle = preference.semesterTitle
		? preference.semesterTitle
		: "-";
	const instituteTitle = preference.instituteTitle
		? preference.instituteTitle
		: "Select institute";

	const vibrate = (element, distance, time) => {
		if (time > 0) {
			element.animate(
				{
					"margin-left": "+=".concat(distance, "px"),
				},
				90,
				vibrate(element, -distance, time - 1)
			);
		}
	};
	useEffect(() => {
		cacheBranches();
		cacheInstitutes();
		$("#branch-preference").text(semesterTitle + " • " + branchTitle);
		$("#institute-preference").text(instituteTitle);
	}, [API_URL]);

	const getData = async (type) => {
		try {
			const response = await axios.get(API_URL + type);
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push({
					title: value.title,
					code: value.code,
				});
			});
			switch (type) {
				case "branches":
					dispatch(setBranchCache(array));
					break;
				case "institutes":
					dispatch(setInstituteCache(array));
					break;
			}
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				2500
			);
		}
	};

	const branch = preference.branch;
	const institute = preference.institute;
	const semester = preference.semester;
	const recentRefreshTimestamp = preference.refreshTimestamp;

	const checkRefreshRequirement = async () => {
		if (!branch || !institute) return;
		let data = {
			branch: branch,
			institute: institute,
			semester: semester,
			timestamp: recentRefreshTimestamp,
		};
		try {
			const response = await axios.post(
				API_URL + "refreshRequirement",
				data
			);
			if (!response) {
				throw "";
			}
			if (response.data || recentRefreshTimestamp == -1) {
				getAndCacheNotes();
			}
		} catch (error) {
			console.log(
				"Error: Something went wrong while checking refresh requirement."
			);
		}
	};

	const getAndCacheNotes = async () => {
		let data = {
			branch: branch,
			institute: institute,
		};
		try {
			$("#note-sync-progress-container").css("display", "flex");
			const response = await axios.post(API_URL + "allnotes", data);
			if (!response) {
				throw "";
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push(value);
			});
			dispatch(setNotesCache(array));
			let preferenceData = {
				branch: preference.branch,
				branchTitle: preference.branchTitle,
				semester: preference.semester,
				semesterTitle: preference.semesterTitle,
				institute: preference.institute,
				instituteTitle: preference.instituteTitle,
				refreshTimestamp: Date.now(),
			};
			dispatch(setPreference(preferenceData));
			$("#note-sync-progress-container").hide();
		} catch (error) {
			console.log(
				"Error: Something went wrong while checking refresh requirement."
			);
		}
	};

	useEffect(() => {
		checkRefreshRequirement();
	}, [API_URL]);

	const onExploreClicked = () => {
		if (!branch || !institute) {
			vibrate($("#change-preference-button"), 20, 6);
			showResponse(TYPE_ERROR, "Please select preference!", 3000);
		} else {
			navigate("/notes");
		}
	};
	const onUploadClicked = () => {
		if (!branch || !institute) {
			vibrate($("#change-preference-button"), 20, 6);
			showResponse(TYPE_ERROR, "Please select preference!", 3000);
		} else {
			navigate("/upload");
		}
	};
	const onStarClick = (note) => {
		const array = [];
		let shouldStarMark = true;
		$.each(starredNotes, (_, starredNote) => {
			if (starredNote._id == note._id) {
				shouldStarMark = false;
			} else {
				array.push(starredNote);
			}
		});
		if (!shouldStarMark) return;
		array.unshift(note);
		dispatch(setStarredNote(array));
	};
	const onUnstarClick = (note) => {
		const array = [];
		$.each(starredNotes, (_, starredNote) => {
			if (starredNote._id != note._id) {
				array.push(starredNote);
			}
		});
		dispatch(setStarredNote(array));
	};
	const onOverlayBackgroundClick = () => {
		$("#sidebar").toggleClass("show-sidebar");
		$("#overlay-background-mobile-sidebar").hide();
	};
	const recentActionButtonToggle = (index) => {
		const element = $("#note-action-container-r" + index);
		if (element.is(":visible")) {
			$(".note-action-container").hide();
		} else {
			$(".note-action-container").hide();
			element.show();
		}
	};
	const starActionButtonToggle = (index) => {
		const element = $("#note-action-container-s" + index);
		if (element.is(":visible")) {
			$(".note-action-container").hide();
		} else {
			$(".note-action-container").hide();
			element.show();
		}
	};

	return (
		<div className="component-container">
			<div id="note-sync-progress-container">
				<div id="note-sync-progress-wrapper">
					<div
						className="progressbar"
						id="note-sync-progressbar"
					></div>
					<p>Sync in progress</p>
				</div>
			</div>
			<Header />

			<div className="component-wrapper">
				<div className="root">
					<div id="homepage-container">
						<div
							onClick={onOverlayBackgroundClick}
							id="overlay-background-mobile-sidebar"
						></div>
						<div id="home-left-container">
							<div className="selected-preference-container">
								<p className="selected-preference-label">
									Branch and Semester
								</p>
								<p
									className="selected-preference"
									id="branch-preference"
								></p>
								<p className="selected-preference-label">
									Institute
								</p>
								<p
									className="selected-preference"
									id="institute-preference"
								></p>
							</div>
							<div id="action-button-container">
								<Link
									to={"/preference"}
									id="change-preference-button"
								>
									Change Preference
								</Link>
								<Link to={"/settings"}>Settings</Link>
							</div>
							<div id="note-explore-button-container">
								<a onClick={onExploreClicked}>
									✧ Explore Notes
								</a>
								<a
									onClick={onUploadClicked}
									id="my-space-button"
								>
									⌑ Upload
								</a>
							</div>
						</div>
						<div id="home-right-container">
							<div id="tab-layout-container">
								<div id="tab-layout">
									<p
										className="tab"
										id="recent-tab"
										onClick={onTabChange}
									>
										<FontAwesomeIcon
											icon={faClock}
											className="tab-icon"
										/>{" "}
										<span>Recent</span>
									</p>
									<p
										className="tab"
										id="starred-tab"
										onClick={onTabChange}
									>
										<FontAwesomeIcon
											icon={faStar}
											className="tab-icon"
										/>{" "}
										<span>Starred</span>
									</p>
									<p
										className="tab"
										id="topics-tab"
										onClick={onTabChange}
									>
										<FontAwesomeIcon
											icon={faFont}
											className="tab-icon"
										/>{" "}
										<span>Topics</span>
									</p>
								</div>
							</div>
							<div id="tab-fragment-container">
								<div
									className="tab-fragment"
									id="recent-tab-fragment"
								>
									{recents.map((note, index) => {
										return NoteModel(
											index,
											note,
											note.pdfUrl,
											false,
											() => onStarClick(note),
											() =>
												recentActionButtonToggle(index),
											() => {},
											() => {},
											"r"
										);
									})}
								</div>
								<div
									className="tab-fragment"
									id="starred-tab-fragment"
								>
									{starredNotes.map((note, index) => {
										return NoteModel(
											index,
											note,
											note.pdfUrl,
											true,
											() => {},
											() => starActionButtonToggle(index),
											() => {
												onUnstarClick(note);
											},
											() => {},

											"s"
										);
									})}
								</div>
								<div
									className="tab-fragment"
									id="topics-tab-fragment"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
