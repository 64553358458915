import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const branchCache = JSON.parse(localStorage.getItem("branchCache"));

const initialState = {
	branchCache: branchCache ? branchCache : [],
};

export const setBranchCache = createAsyncThunk(
	"branchCache/set",
	async (branchCache, thunkk) => {
		localStorage.setItem("branchCache", JSON.stringify(branchCache));
		return branchCache;
	}
);

export const branchCacheSlice = createSlice({
	name: "branchCache",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setBranchCache.fulfilled, (state, action) => {
			state.branchCache = action.payload;
		});
	},
});

export default branchCacheSlice.reducer;
