import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const recents = JSON.parse(localStorage.getItem("recents"));

const initialState = {
	recents: recents ? recents : [],
};

export const setRecents = createAsyncThunk(
	"recents/set",
	async (recents, thunk) => {
		localStorage.setItem("recents", JSON.stringify(recents));
		return recents;
	}
);

export const recentNotesSlice = createSlice({
	name: "recents",
	initialState: initialState,
	extraReducers: (builder) => {
		builder.addCase(setRecents.fulfilled, (state, action) => {
			state.recents = action.payload;
		});
	},
});

export default recentNotesSlice.reducer;
