import "../style/css/ResponseHelper.css";
import { hideResponse } from "./ResponseUtil";
const ResponseHelper = () => {
	return (
		<div id="response-container">
			<div id="response">
				<p onClick={hideResponse} id="hide-response">
					✕
				</p>
				<div id="response-message-wrapper">
					<p id="response-message"></p>
					<div id="response-overlay"></div>
				</div>
			</div>
		</div>
	);
};
export default ResponseHelper;
