import axios from "axios";
import { useSelector } from "react-redux";
import showResponse from "../ResponseUtil";
import $ from "jquery";
import { hideProgressbar, showProgressbar } from "../ProgressbarHelper";
const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";
// const API_URL = "http://localhost:8080/api/";
const GenerateMAPIN = () => {
	const { instituteCache } = useSelector((state) => state.instituteCache);
	const { branchCache } = useSelector((state) => state.branchCache);
	const generateRandomNumber = (length) => {
		return Math.floor(
			Math.pow(10, length - 1) +
				Math.random() *
					(Math.pow(10, length) - Math.pow(10, length - 1) - 1)
		);
	};
	const { user } = useSelector((state) => state.auth);

	const API_HEADER_CONFIG = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	const onSubmit = async () => {
		let data = {
			name: $("#mapin-name-input").val(),
			isMaster: $("#mapin-ismaster-select").val(),
			pin: $("#mapin-pin-input").val(),
			primaryBranch: $("#mapin-primarybranch-select").val(),
			primaryInstitute: $("#mapin-primaryinstitute-select").val(),
			otherBranch: $("#mapin-otherbranchallowed-select").val(),
			otherInstitute: $("#mapin-otherinstituteallowed-select").val(),
		};
		try {
			showProgressbar("#mapinSubmitProgressbar", "#mapin-submit-button");
			const response = await axios.post(
				API_URL + "setmapin",
				data,
				API_HEADER_CONFIG
			);
			hideProgressbar("#mapinSubmitProgressbar", "#mapin-submit-button");
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			showResponse(TYPE_SUCCESS, response.data.message);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message
			);
			hideProgressbar("#mapinSubmitProgressbar", "#mapin-submit-button");
		}
	};

	return (
		<div className="selection-wrapper">
			<div className="selection-option-row">
				<p className="selection-label">Name</p>

				<input
					id="mapin-name-input"
					className="selection-select"
					type={"text"}
				/>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">Pin</p>

				<input
					id="mapin-pin-input"
					className="selection-select"
					type={"text"}
					value={generateRandomNumber(5)}
					disabled
				/>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">isMaster</p>

				<select id="mapin-ismaster-select" className="selection-select">
					<option value="0">No</option>
					<option value="1">Yes</option>
				</select>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">Primary Branch</p>

				<select
					id="mapin-primarybranch-select"
					className="selection-select"
				>
					{branchCache.map((branch, index) => {
						return (
							<option key={index} value={branch.code}>
								{branch.title}
							</option>
						);
					})}
				</select>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">Primary Institute</p>

				<select
					id="mapin-primaryinstitute-select"
					className="selection-select"
				>
					{instituteCache.map((institute, index) => {
						return (
							<option key={index} value={institute.code}>
								{institute.title}
							</option>
						);
					})}
				</select>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">Other branch allowed</p>
				<select
					id="mapin-otherbranchallowed-select"
					className="selection-select"
				>
					<option value="0">No</option>
					<option value="1">Yes</option>
				</select>
			</div>
			<div className="selection-option-row">
				<p className="selection-label">Other institute allowed</p>
				<select
					id="mapin-otherinstituteallowed-select"
					className="selection-select"
				>
					<option value="0">No</option>
					<option value="1">Yes</option>
				</select>
			</div>
			<div
				className="selection-option-row"
				id="mapin-submit-button-container"
			>
				<div className="progressbar" id="mapinSubmitProgressbar"></div>
				<button
					className="submit-button"
					id="mapin-submit-button"
					onClick={onSubmit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};
export default GenerateMAPIN;
