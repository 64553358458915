/* eslint-disable react-hooks/exhaustive-deps */
import "../style/css/Auth.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faCheckCircle,
	faRemove,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";

let errorTimer;
let errorTimeout;
let passwordIsStrong = false;

let email;
const NewPass = () => {
	const navigate = useNavigate();

	useEffect(() => {
		verifyToken();
	}, []);

	async function verifyToken() {
		showProgressbar("#newPassProgressBar");
		showProgressbar("#newpass-link-verify-wrapper", ".form-container");
		let url = new URL(window.location);
		let token = url.searchParams.get("token");
		let data = {
			token: token,
		};
		try {
			let response = await axios.post(API_URL + "passresettoken/get", data);
			hideProgressbar("#newPassProgressBar");
			hideProgressbar("#newpass-link-verify-wrapper", ".form-container");
			if (!response) {
				$(".form-container").hide();
				showGlobalError("Something went wrong");
				return;
			}
			if (response.data.data == null) {
				$(".form-container").hide();
				showGlobalError("Sorry the link is expired.", "#global-error-text");
				return;
			}
			email = response.data.data.email;
		} catch (error) {
			hideProgressbar("#newPassProgressBar");
			hideProgressbar("#newpass-link-verify-wrapper", ".form-container");
			showError(error.message);
		}
	}
	const [formData, setFormData] = useState({
		password: "",
		cpassword: "",
	});

	const { password, cpassword } = formData;

	const showProgressbar = (progressbar, element) => {
		$(progressbar).show();
		if (element) {
			$(element).hide();
		}
	};

	const hideProgressbar = (progressbar, element) => {
		$(progressbar).hide();
		if (element) {
			$(element).show();
		}
	};

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!passwordIsStrong) {
			showError("Choose strong password");
			return;
		}
		if (password !== cpassword) {
			showError("Password didn't match");
			return;
		}
		const data = {
			email: email,
			password: password,
		};
		try {
			showProgressbar("#newPassSubmitProgressBar", "#newpassSubmitButton");
			let response = await axios.post(API_URL + "/password/update", data);
			hideProgressbar("#newPassSubmitProgressBar", "#newpassSubmitButton");
			if (!response) {
				showError("Something went wrong");
				return;
			}
			$("#password-updated-message").show();
			$("#password-update-form").hide();
		} catch (error) {
			hideProgressbar("#newPassSubmitProgressBar", "#newpassSubmitButton");
			showError(error.message);
		}
	};

	const showGlobalError = (message) => {
		$("#global-error-text").show().text(message);
	};
	const showError = (message) => {
		clearInterval(errorTimer);
		let timeout = 5;
		errorTimer = setInterval(
			(function f() {
				if (timeout < 1) {
					clearInterval(errorTimer);
				}
				return f;
			})(),
			1000
		);
		$("#newpass-error-div").show();
		$("#newpass-error-message").text(message);

		hideResponseAfterDelay();
	};
	const hideResponseAfterDelay = () => {
		clearTimeout(errorTimeout);
		errorTimeout = setTimeout(() => {
			$("#newpass-error-div").hide();
		}, 5000);
	};
	$("#newpass-error-remove-button").on("click", () => {
		clearTimeout(errorTimeout);
		$("#newpass-error-div").hide();
	});

	useEffect(() => {
		checkStrength(password);
	}, [password]);

	function checkStrength(password) {
		let strength = 0;

		//If password contains both lower and uppercase characters
		if (password.match(/([a-z])|([A-Z])/)) {
			strength += 1;
		}
		if (password.match(/([A-Z])/) && password.length > 7) {
			strength += 1;
		}
		//If it has numbers and characters
		if (password.match(/([0-9])/)) {
			strength += 1;
		}
		//If it has one special character
		if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
			strength += 1;
		}
		//If password is greater than 7
		if (password.length > 7) {
			strength += 1;
		}

		showPasswordStrength(strength);
	}
	const showPasswordStrength = (level) => {
		let background = "#fff";

		// eslint-disable-next-line default-case
		switch (level) {
			case 1:
				background = "#ff4848";
				break;
			case 2:
				background = "#ffc048";
				break;
			case 3:
				background = "#f2ff48";
				break;
			case 4:
				background = "#62ff48";
				break;
			case 5:
				background = "#309f16";
				break;
		}
		if (level > 2) {
			passwordIsStrong = true;
		} else {
			passwordIsStrong = false;
		}
		$(".password-strength")
			.width(20 * level + "%")
			.css("background", background);
	};
	return (
		<>
			<p id="global-error-text"></p>
			<div className="error-div" id="newpass-error-div">
				<p className="error-message" id="newpass-error-message"></p>
				<p
					onClick={() => {
						$("#newpass-error-div").hide();
					}}
					className="error-remove-button"
					id="newpass-error-remove-button"
				>
					<FontAwesomeIcon icon={faRemove} />
				</p>
			</div>
			<div id="newpass-link-verify-wrapper">
				<div className="progressbar" id="newPassProgressBar"></div>
				<p>Please wait ...</p>
			</div>

			<div className="form-container">
				<div className="form-wrapper">
					<div className="auth-page-info-wrapper">
						<button
							onClick={() => {
								navigate("/login");
							}}
						>
							<FontAwesomeIcon icon={faArrowLeft} /> Back to login
						</button>
						<h2>
							Set a new password <br />
						</h2>
						<p>
							This will update your current password so that you can login
							easily
						</p>
					</div>
					<div className="auth-form">
						<h2 className="auth-heading">New Password</h2>
						<p id="password-updated-message">
							<span>
								<FontAwesomeIcon icon={faCheckCircle} />
							</span>
							<br />
							<br />
							Your password is updated successfully, You can now login with new
							password.
						</p>
						<div id="password-update-form">
							<div className="input-field">
								<p className="input-label">Password</p>
								<input
									className="input-box"
									type="password"
									name="password"
									value={password}
									onChange={onChange}
								/>
								<div className="password-strength-wrapper">
									<div className="password-strength"></div>
								</div>
							</div>
							<div className="input-field">
								<p className="input-label">Confirm Password</p>
								<input
									className="input-box"
									type="password"
									name="cpassword"
									value={cpassword}
									onChange={onChange}
								/>
							</div>
							<button
								onClick={onSubmit}
								className="input-box submit-button"
								id="newpassSubmitButton"
								value={1}
							>
								Submit
							</button>
							<div className="progressbar" id="newPassSubmitProgressBar"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewPass;
