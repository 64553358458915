const UC = () => {
	return (
		<>
			<h2 style={{ margin: "100px", color: "red", fontSize: "40px" }}>
				This section is under construction !!!
			</h2>
		</>
	);
};

export default UC;
