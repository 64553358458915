import "../style/css/Upload.css";
import { hideProgressbar, showProgressbar } from "./ProgressbarHelper";
import showResponse from "./ResponseUtil";
import Toolbar from "./Toolbar";
import $ from "jquery";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { disable } from "workbox-navigation-preload";
const TYPE_ERROR = 0;
const TYPE_SUCCESS = 1;
const API_URL = "https://engineernotes-92060.el.r.appspot.com/api/";

// const API_URL = "http://localhost:8080/api/";

const Upload = () => {
	const navigate = useNavigate();
	const { instituteCache } = useSelector((state) => state.instituteCache);
	const { branchCache } = useSelector((state) => state.branchCache);

	const [subjects, setSubjects] = useState([]);

	const { user } = useSelector((state) => state.auth);
	useEffect(() => {
		if (!user) {
			navigate("/register", { state: { isFromUpload: true } });
		}
	}, [API_URL]);
	const API_HEADER_CONFIG = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
			"Content-Type": "multipart/form-data",
		},
	};
	const API_HEADER_CONFIG2 = {
		headers: {
			Authorization: `Bearer ${user ? user.token : ""}`,
		},
	};
	const uploadNotes = async (event) => {
		event.preventDefault();
		const title = $("#pdf-title-input").val();
		const subject = $("#pdf-subject-select").val();
		const branch = $("#pdf-branch-select").val();
		const institute = $("#pdf-institute-select").val();
		const notepdf = $("#pdf-file-input").prop("files")[0];
		if (title.trim() === "") {
			showResponse(TYPE_ERROR, "Title is required");
			return;
		}
		if (subject.trim() === "") {
			showResponse(TYPE_ERROR, "Subject is required");
			return;
		}
		if (branch.trim() === "") {
			showResponse(TYPE_ERROR, "Branch is required");
			return;
		}
		if (institute.trim() === "") {
			showResponse(TYPE_ERROR, "Institute is required");
			return;
		}
		if (!notepdf) {
			showResponse(TYPE_ERROR, "Please select  note PDF");
			return;
		}
		if (notepdf.size / (1024 * 1024) > 16) {
			showResponse(TYPE_ERROR, "Note should be less than 16 Mb!");
			return;
		}
		if (notepdf.type !== "application/pdf") {
			showResponse(TYPE_ERROR, "Note should be in pdf format!");
			return;
		}
		let fileName = title.replace(/\s+/g, "-") + "_" + Date.now();

		const formData = new FormData();
		formData.append("title", title);
		formData.append("pdfFileName", fileName + ".pdf");
		formData.append("subject", subject);
		formData.append("branch", branch);
		formData.append("institute", institute);
		formData.append("notepdf", notepdf, fileName);
		try {
			showProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			const note = await axios.post(
				API_URL + "note/upload",
				formData,
				API_HEADER_CONFIG
			);
			if (!note) {
				hideProgressbar(
					"#toolbarProgressbar",
					"#toolbar-action-button"
				);

				showResponse(TYPE_ERROR, "Something went wrong!");
				return;
			}
			submitRefreshRequirement();
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				3000
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
		}
	};

	const getSubjects = async () => {
		let data = {
			branchCode: $("#pdf-branch-select").val(),
			semester: $("#pdf-semester-select").val(),
		};
		try {
			showProgressbar("#pdfSemesterProgressbar", "#pdf-subject-select");
			const response = await axios.post(API_URL + "subjects", data);
			hideProgressbar("#pdfSemesterProgressbar", "#pdf-subject-select");
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong");
				return;
			}
			let array = [];
			$.each(response.data, (_, value) => {
				array.push({
					title: value.title,
					code: value.code,
				});
			});
			setSubjects(array);
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				3000
			);
			hideProgressbar("#pdfSemesterProgressbar", "#pdf-subject-select");
		}
	};

	useEffect(() => {
		getSubjects();
	}, [API_URL]);

	const submitRefreshRequirement = async () => {
		const branch = $("#pdf-branch-select").val();
		const institute = $("#pdf-institute-select").val();
		const semester = $("#pdf-semester-select").val();
		let data = {
			branch: branch,
			institute: institute,
			semester: semester,
		};
		try {
			const response = await axios.post(
				API_URL + "setRefreshRequirement",
				data,
				API_HEADER_CONFIG2
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
			if (!response) {
				showResponse(TYPE_ERROR, "Something went wrong!");
				return;
			}
			showResponse(TYPE_SUCCESS, "Note uploaded successfully");
		} catch (error) {
			showResponse(
				TYPE_ERROR,
				error.response.data
					? error.response.data.message
					: error.message,
				3000
			);
			hideProgressbar("#toolbarProgressbar", "#toolbar-action-button");
		}
	};

	return (
		<div className="component-container">
			{Toolbar("Upload note", "Submit", uploadNotes)}
			<div className="component-wrapper">
				<div className="root">
					<div id="upload-container">
						<div className="selection-wrapper">
							<div className="selection-option-row">
								<p className="selection-label">Title</p>
								<div
									className="progressbar"
									id="branchProgressbar"
								></div>
								<input
									id="pdf-title-input"
									className="selection-select"
									type={"text"}
								/>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">Subject</p>

								<div
									className="progressbar"
									id="pdfSemesterProgressbar"
								></div>
								<select
									id="pdf-subject-select"
									className="selection-select"
								>
									{subjects.map((branch, index) => {
										return (
											<option
												key={index}
												value={branch.code}
											>
												{branch.title}
											</option>
										);
									})}
								</select>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">Semester</p>

								<div
									className="progressbar"
									id="semesterProgressbar"
								></div>
								<select
									id="pdf-semester-select"
									className="selection-select"
									onChange={getSubjects}
								>
									<option value="1">I & II Sem</option>
									<option value="3">III & IV Sem</option>
									<option value="5">V & VI Sem</option>
									<option value="7">VII & VIII Sem</option>
								</select>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">Branch</p>

								<div
									className="progressbar"
									id="branchProgressbar"
								></div>
								<select
									id="pdf-branch-select"
									className="selection-select"
									onChange={getSubjects}
									defaultValue={
										user ? user.primaryBranch : ""
									}
									disabled={
										user
											? user.otherBranchUpload
												? ""
												: "disabled"
											: "disabled"
									}
								>
									{branchCache.map((branch, index) => {
										return (
											<option
												key={index}
												value={branch.code}
											>
												{branch.title}
											</option>
										);
									})}
								</select>
							</div>

							<div className="selection-option-row">
								<p className="selection-label">Institute</p>

								<div
									className="progressbar"
									id="branchProgressbar"
								></div>
								<select
									id="pdf-institute-select"
									className="selection-select"
									defaultValue={
										user ? user.primaryInstitute : ""
									}
									disabled={
										user
											? user.otherInstituteUpload
												? ""
												: "disabled"
											: "disabled"
									}
								>
									{instituteCache.map((institute, index) => {
										return (
											<option
												key={index}
												value={institute.code}
											>
												{institute.title}
											</option>
										);
									})}
								</select>
							</div>
							<div className="selection-option-row">
								<p className="selection-label">PDF File</p>

								<div
									className="progressbar"
									id="branchProgressbar"
								></div>
								<input
									id="pdf-file-input"
									className="selection-select"
									type={"file"}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Upload;
